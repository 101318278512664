<template>
  <div class="row justify-content-md-center">
    <div class="col-md-3 mb-5">
      <div class="card card-custom card-stretch">
        <div class="card-body pt-8">
          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4 active"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/General/User.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Informasi Pengguna</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/Communication/Shield-user.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Ubah Kata Sandi</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9 mb-5">
      <!--begin::Content-->
      <div class="flex-row-auto ml-lg-8">
        <b-card class="p-5">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                  <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                  </b-card>

                  <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                  </b-card>

                  <b-skeleton type="button"></b-skeleton>
                </template>
                <b-card-text>
                  <div class="row">
                    <div class="col-lg-12">
                      <b-form-group
                        id="first_name"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="6"
                        content-cols-lg="7"
                        label="Nama Depan"
                        label-for="first_name"
                      >
                        <b-form-input
                          id="first_name"
                          ref="first_name"
                          type="text"
                          v-model="form.first_name"
                          :disabled="!isEditing"
                          placeholder="Nama Depan"
                          :class="{ view: !isEditing }"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="last_name"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="6"
                        content-cols-lg="7"
                        label="Nama Belakang"
                        label-for="last_name"
                      >
                        <b-form-input
                          id="last_name"
                          ref="last_name"
                          type="text"
                          v-model="form.last_name"
                          :disabled="!isEditing"
                          placeholder="Nama Belakang"
                          :class="{ view: !isEditing }"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="example-input-group-3"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="6"
                        content-cols-lg="7"
                        label-for="select-direktorat"
                        label="Direktorat"
                      >
                        <b-form-select
                          id="select-direktorat"
                          v-model="form.direktorat"
                          :options="direktorats"
                          :disabled="!isEditing"
                          :class="{ view: !isEditing }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >&#45;&#45; Pilih Salah Satu
                              &#45;&#45;</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>

                      <b-form-group
                        id="email"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="6"
                        content-cols-lg="7"
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          ref="email"
                          type="email"
                          v-model="form.email"
                          :disabled="true"
                          placeholder="Email"
                          :class="{ view: !isEditing }"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="nip"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="6"
                        content-cols-lg="7"
                        label="NIP"
                        label-for="nip"
                      >
                        <b-form-input
                          id="nip"
                          ref="nip"
                          type="text"
                          v-model="form.nip"
                          :disabled="!isEditing"
                          placeholder="NIP"
                          :class="{ view: !isEditing }"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="nik"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="6"
                        content-cols-lg="7"
                        label="NIK"
                        label-for="nik"
                      >
                        <b-form-input
                          id="nik"
                          ref="nik"
                          type="text"
                          v-model="form.nik"
                          :disabled="!isEditing"
                          placeholder="NIK"
                          :class="{ view: !isEditing }"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="tlp"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="6"
                        content-cols-lg="7"
                        label="No. Telepon"
                        label-for="no_telp"
                      >
                        <b-form-input
                          id="no_telp"
                          ref="no_telp"
                          type="number"
                          v-model="form.no_telp"
                          :disabled="!isEditing"
                          placeholder="No. Telepon"
                          :class="{ view: !isEditing }"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-7">
                    <div class="col-lg-6">
                      <button
                        @click.prevent.stop="isEditing = !isEditing"
                        v-if="!isEditing"
                        class="btn btn-ubah"
                      >
                        Ubah
                      </button>
                      <button
                        @click.prevent.stop="isEditing = false"
                        class="btn btn-ubah"
                        v-else-if="isEditing"
                      >
                        Batal
                      </button>
                      <button
                        v-if="isEditing"
                        class="btn btn-download ml-2"
                        @click.prevent.stop="save"
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </b-card-text>
              </b-skeleton-wrapper>
            </b-tab>
            <b-tab>
              <b-card-text>
                <form
                  class="form"
                  id="kt_change_password_form"
                  novalidate="novalidate"
                >
                  <div class="form-group row">
                    <label
                      for="oldPassword"
                      class="col-sm-2 col-lg-3 col-form-label"
                      >Kata Sandi Lama</label
                    >
                    <div class="col-sm-10 col-lg-9">
                      <input
                        class="form-control"
                        id="oldPassword"
                        type="password"
                        name="oldPassword"
                        ref="oldPassword"
                        :disabled="!isChanging"
                        :class="{ view: !isChanging }"
                        placeholder="Kata Sandi Lama"
                        v-model="formReg.oldPassword"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="password"
                      class="col-sm-2 col-lg-3 col-form-label"
                      >Kata Sandi Baru</label
                    >
                    <div class="col-sm-10 col-lg-9">
                      <input
                        id="password"
                        class="form-control"
                        type="password"
                        name="password"
                        ref="password"
                        :disabled="!isChanging"
                        :class="{ view: !isChanging }"
                        placeholder="Kata Sandi Baru"
                        v-model="formReg.password"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="cpassword"
                      class="col-sm-2 col-lg-3 col-form-label"
                      >Konfirmasi Kata Sandi</label
                    >
                    <div class="col-sm-10 col-lg-9">
                      <input
                        id="cpassword"
                        class="form-control"
                        type="password"
                        name="cpassword"
                        ref="cpassword"
                        :disabled="!isChanging"
                        :class="{ view: !isChanging }"
                        placeholder="Konfirmasi Kata Sandi"
                        v-model="formReg.cpassword"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="pb-lg-0 pb-5">
                    <button
                      @click.prevent.stop="isChanging = !isChanging"
                      v-if="!isChanging"
                      class="btn btn-ubah"
                    >
                      Ubah
                    </button>
                    <button
                      @click.prevent.stop="isChanging = false"
                      class="btn btn-ubah"
                      v-else-if="isChanging"
                      @click="resetFormPassword"
                    >
                      Batal
                    </button>
                    <button
                      v-if="isChanging"
                      class="btn btn-download ml-2"
                      ref="kt_change_password_submit"
                    >
                      Simpan
                    </button>
                  </div>
                </form>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { handleErrors } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

let token = "";

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  name: "Profil",
  computed: {
    ...mapGetters(["currentUserPhoto"])
  },
  data() {
    return {
      form: {
        id: null,
        first_name: "",
        last_name: "",
        nip: "",
        nik: "",
        email: "",
        no_telp: "",
        direktorat: null
      },
      formReg: {
        oldPassword: "",
        password: "",
        cpassword: ""
      },
      user: {},
      direktorats: [],
      isLoading: true,
      isEditing: false,
      isChanging: false,
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/master/direktorat?take=100`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const detailMenu = items.data;
          let selectedOptions;
          selectedOptions = [];
          detailMenu.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.direktorats = selectedOptions;
        });
      this.getDataUser();
    });

    const change_password = KTUtil.getById("kt_change_password_form");

    this.fv = formValidation(change_password, {
      fields: {
        oldPassword: {
          validators: {
            notEmpty: {
              message: "Kata Sandi Lama harus diisi"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Kata Sandi Baru harus diisi"
            },
            stringLength: {
              min: 8,
              message: "Kata Sandi Baru minimal 8 karakter"
            },
            regexp: {
              regexp: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              message: "Kata Sandi Baru harus berisi huruf besar, huruf kecil, angka, dan karakter spesial"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Konfirmasi Kata Sandi harus diisi"
            },
            identical: {
              compare: function() {
                return change_password.querySelector('[name="password"]').value;
              },
              message: "Kata Sandi tidak sama"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      let data = {
        oldPassword: this.formReg.oldPassword,
        password: this.formReg.password,
        passwordConfirm: this.formReg.cpassword
      };

      swalSuccess.fire({
        title: "Ubah Kata Sandi",
        text: "Anda yakin ingin mengubah kata sandi akun?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.doChangePassword(data);
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Silakan lengkapi data Anda!",
        icon: "error",
        confirmButtonColor: "#18328D",
        heightAuto: false
      });
    });
  },
  methods: {
    doChangePassword(data, attempt = 0) {
      attempt++;

      // set spinner to submit button
      const submitButton = this.$refs["kt_change_password_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      return fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/auth/password`),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          },
          body: JSON.stringify(data)
        }
      )
        .then(response => response.json())
        .then(data => {
          console.log(data)

          if (data.status == 500 && attempt <= 3) {
            return this.doChangePassword(data, attempt);

          } else if (data.status == 'error') {
            throw data
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Data berhasil diubah",
              showConfirmButton: false,
              timer: 1500
            });
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.resetFormPassword();
            this.isChanging = false;
          }

        })
        .catch(error => {
          console.error(error);
          Swal.fire({
            title: "Kesalahan",
            text: "Kata Sandi Lama yang Anda masukkan salah",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Kembali",
            confirmButtonColor: "#18328D"
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formPassword[name];
      return $dirty ? !$error : null;
    },
    resetFormPassword() {
      this.fv.resetForm();
      this.formReg = {
        oldPassword: null,
        password: null,
        passwordConfirm: null
      };
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    getDataUser() {
      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/auth/user`), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token
        }
      })
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.user = data;
          this.form.first_name = this.user.first_name;
          this.form.last_name = this.user.last_name;
          this.form.email = this.user.email;
          this.form.nip = this.user.nip;
          this.form.id = this.user.id;
          this.form.nik = this.user.nik;
          this.form.no_telp = this.user.no_telp;
          this.form.direktorat = this.user.direktorat_id;
        });
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-white"];
      } else {
        return ["bg-white", "text-dark"];
      }
    },
    save() {
      let data = {
        direktorat_id: this.form.direktorat.toString(),
        first_name: this.form.first_name,
        id: null,
        last_name: this.form.last_name,
        nik: this.form.nik,
        nip: this.form.nip,
        no_telp: this.form.no_telp
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/users/update-profile`),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.getDataUser();
              this.isEditing = false;
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    }
  }
};
</script>

<style scoped>
.view {
  border-color: transparent;
  background-color: initial;
  color: initial;
}
</style>
